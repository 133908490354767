@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

:root {

  /* Color */
  --primary: #9a876a;
  --black: #000000;
  --white: #fff;
  --lightbg: #F5F8F9;

  /* Fonts */
  --font_1: "Nunito Sans", sans-serif;
  --font_2: "Playfair Display", serif;
}

body {
  font-family: var(--font_1);
}

@media(max-width:1024px) {

  body,
  html {
    overflow-x: hidden;
  }
}

body {
  color: #333;
  font-size: 15px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  /* line-height: 26px; */
  overflow-x: hidden;
  /* letter-spacing: .4px; */
}

body {
  font-family: var(--font_1);
}

input::placeholder {
  color: var(--primary) !important;
  opacity: 20% !important;
}

.rcs_logo_head.rcs_sticky-top .showcase_center_header {
  display: none;
}

.sticky_logo {
  display: none;
}

.rcs_logo_head.rcs_sticky-top .sticky_logo {
  display: block;
  transition: all 0.5s ease;
}

.rcs_logo_head.rcs_sticky-top .sticky_logo img {
  max-width: 200px;
}

.rcs_logo_head.rcs_sticky-top .rcs_menu.menu_list_main>.container-fluid {
  max-width: 1600px;
  transition: all 0.5s ease;
}

/* BANNER SLIDER */
.home_banner_content {
  width: 650px;
  position: relative;
  left: 200px;
}

.rcs_hero_img h1 {
  font-family: 'Playfair Display', sans-serif;
  color: #2c2c2c;
  font-size: 70px;
  line-height: 70px;
  font-weight: 400;
  transition: color .2s linear;
  letter-spacing: .8px;
}

.rcs_hero_img h1 span {
  display: block;
  font-family: sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  line-height: 54px;
}

.rcs_hero_img p {
  width: 58%;
  margin-left: auto;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.rcs_hero_img .home-banner-btn1 {
  background: var(--primary);
  border: 2px solid var(--primary);
}

.rcs_hero_img .home-banner-btn2 {
  border: 2px solid #000;
  color: #000;
  background: transparent;
}

.outline-base-btn span {
  margin-left: 5px;
  font-size: 22px;
}

.outline-base-btn {
  width: 45%;
  margin-top: 30px;
  padding: 11px 4px;
  float: right;
  margin-right: 10px;
  border-radius: 0px;
  font-weight: 800;
  text-transform: uppercase;
  box-shadow: none !important;
}

.rcs_hero_img .outline-base-btn:hover,
.rcs_hero_img .outline-base-btn:active,
.rcs_hero_img .outline-base-btn:focus,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--white);
  color: var(--primary);
  border-color: var(--white);
}


.outline-base-btn.small {
  padding: 5px 15px;
}

.rcs_hero_slider {
  min-height: 800px;
  display: grid;
  align-items: center;
  background-size: cover;
  background-position: right top;
}

.heading-wrapper {
  padding-bottom: 35px;
}

.heading-wrapper span {
  font-size: 24px;
  line-height: 26px;
  font-family: var(--primary-base);
  padding-bottom: 5px;
  display: block;
  color: var(--primary);
}

.heading-wrapper h2 {
  font-family: var(--font_2);
  color: var(--primary);
  font-weight: 400;
  font-size: 40px;
  line-height: 55px;
}

.heading-wrapper p {
  margin: 0 auto;
  font-size: 16px;
  line-height: 27px;
}

.showcase-container {
  max-width: 1850px;
  margin: auto;
}

/* .section-space {
  padding: 100px 0;
}

.showcase-container {
  padding: 0 100px;
} */

/* .diamonds-collection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  row-gap: 15px;
  position: relative;
}

.diamonds-collection::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  width: 10px;
  height: 100%;
  background: url("./img/divider.png") no-repeat center/cover;
}

.diamonds-collection .diamond-item {
  text-align: center;
  cursor: pointer;
}

.diamonds-collection .diamond-item img {
  width: 120px;
  margin: auto;
}

.diamonds-collection .diamond-item p {
  font-size: 20px;
  line-height: 24px;
  font-family: var(--font_1);
  padding-top: 10px;
}

.diamonds-item-brief {
  width: 80%;
  margin: 0 auto;
}

.diamonds-item-brief .content h2 {
  font-family: var(--primary-medium);
  font-size: 30px;
  line-height: 28px;
  letter-spacing: -1px;
}

.diamonds-item-brief .content p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 20px;
}

.diamonds-item-brief img {
  max-width: 280px;
  height: 280px;
  object-fit: contain;
  margin-right: 20px;
}

.diamonds-item-brief .content .outline-base-btn {
  border: 1px solid var(--primary);
  color: var(--primary);
}

.diamonds-item-brief .content .outline-base-btn:hover {
  color: var(--white);
  background-color: var(--primary);
}

.shop-diamond-by-shape {
  position: relative;
}

.shop-diamond-by-shape::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 10px;
  background: url("./img/divider-2.png ") no-repeat center/cover;
}

.explore-collection .item img {
  max-width: 100%;
  transition: all .5s ease;
}

.explore-collection .item:hover img {
  transform: scale(1.1);
}

.explore-collection .item a {
  font-size: 24px;
  line-height: 36px;
  color: var(--black);
  padding-top: 15px;
  display: block;
}

.explore-collection .item:hover a {
  font-weight: 800;
}

.explore-collection .item .img-box {
  overflow: hidden;
  padding-top: 0px;
}

.design-diamond-ring-section {
  background-color: var(--primary);
}

.design-diamond-ring-section .heading-wrapper * {
  color: var(--white);
}

.design-diamond-ring-section .heading-wrapper p {
  width: 60%;
}

.design-diamond-ring-section .heading-wrapper h2 {
  font-size: 50px;
  text-transform: uppercase;
  line-height: 1;
}

.design-diamond-ring-section .heading-wrapper span {
  text-transform: uppercase;
}

.design-diamond-ring-section .heading-wrapper span {
  font-weight: 400;
}

.design-diamond-ring-section .content span {
  display: inline-block;
  opacity: 0.15;
  font-size: 50px;
  line-height: 60px;
  font-family: var(--primary-bold);
  padding-bottom: 15px;
}

.design-diamond-ring-section .content * {
  color: var(--white);
}

.design-diamond-ring-section .content h3 {
  font-family: var(--primary-base);
  font-size: 26px;
  line-height: 25px;
  padding-bottom: 15px;
  margin: 0;
}

.design-diamond-ring-section .content p {
  padding-bottom: 25px;
  font-size: 14px;
  margin: 0;
  width: 80%;
}

.design-diamond-ring-section .content .outline-base-btn {
  border: 1px solid var(--white);
  font-weight: 400;
  text-transform: capitalize;
  font-size: 14px;
  font-family: var(--font_1);
}

.design-diamond-ring-section .content .outline-base-btn:hover {
  background: #fff;
  color: #000;
}

.design-diamond-ring-section,
.design-diamond-ring-section .video-item {
  position: relative;
}

.design-diamond-ring-section::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url("./img/shape.png") no-repeat center/cover;
  width: 200px;
  height: 200px;
}

.design-diamond-ring-section .video-item video {
  width: 100%;
}

.design-diamond-ring-section .video-item .content {
  position: absolute;
  top: 0;
  left: 50px;
}

.shop-ring-by-style .item a {
  display: block;
  font-family: var(--font_1);
  color: var(--black);
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  padding-top: 15px;
}

.shop-ring-by-style .item:hover a {
  font-weight: 700;
}

.shop-ring-by-style {
  position: relative;
}

.shop-ring-by-style .slick-slider .slick-arrow {
  position: absolute;
  top: -70px;
  right: 0;
  left: auto;
  width: 30px;
  height: auto;
}

.shop-ring-by-style .slick-slider .slick-arrow.slick-prev {
  right: 40px;
}

.gemstone-glamour .heading-wrapper h2,
.gemstone-glamour .heading-wrapper span {
  text-transform: uppercase;
}

.gemstone-glamour .heading-wrapper p {
  width: 50%;
  margin: 0 auto;
}

.gemstone-glamour .item {
  text-align: center;
}

.gemstone-glamour .item p {
  font-size: 12px;
  color: var(--white);
  padding-top: 10px;
}

.gemstone-glamour {
  background: url("./img/gemstone-bg.jpg") no-repeat center/cover;
}

.gemstone-glamour .heading-wrapper * {
  color: var(--white);
}

.gemstone-glamour .outline-base-btn {
  color: var(--white);
  border: 1px solid var(--white);
}

.gemstone-glamour .outline-base-btn:hover {
  background: #fff;
  color: var(--primary);
  border: 1px solid var(--primary);
} */

.custom-design .heading-wrapper {
  padding-left: 100px;
  margin: 0 auto;
}

.custom-design .outline-base-btn {
  border: 1px solid var(--primary);
  text-transform: uppercase;
}

.custom-design .outline-base-btn:hover {
  background: var(--primary);
  color: var(--white);
}

/* .consult-specialist-section div[class*="col-"]:first-child {
  background-color: var(--primary);
}

.consult-specialist-section .inner-wrap {
  width: 70%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  place-content: center;
  justify-content: center;
}

.consult-specialist-section .inner-wrap * {
  color: var(--white);
}

.consult-specialist-section .inner-wrap span {
  font-size: 24px;
  line-height: 30px;
  font-family: var(--primary-base);
  text-transform: uppercase;
}

.consult-specialist-section .inner-wrap h2 {
  font-family: var(--primary-medium);
  text-transform: uppercase;
  font-size: 50px;
  line-height: normal;
}

.consult-specialist-section .inner-wrap p {
  font-size: 16px;
  line-height: 20px;
}

.consult-specialist-section .inner-wrap a {
  border: 1px solid var(--white);
  width: fit-content;
  margin: 0 auto;
}

.consult-specialist-section .inner-wrap a:hover {
  background: #fff;
  color: var(--primary);
}

.promises-section {
  position: relative;
}

.promises-section::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 10px;
  background: url("./img/divider-2.png ") no-repeat center/cover;
}

.promises-section .item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.promises-section .row div[class*="col-"] {
  position: relative;
}

.promises-section .row div[class*="col-"]:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  width: 2px;
  height: calc(100% + 40px);
  background-color: var(--primary);
  opacity: 0.3;
}

.promises-section .row.align-items-center>div[class*="col-"]:first-child:before,
.promises-section .row div[class*="col-"]:last-child:before {
  display: none;
}

.promises-section .item p {
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 5%;
  font-family: var(--font_2);
  margin-left: 15px;
} */

/* .rcs_customer_say_section .outline-base-btn {
  background: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);
}

.rcs_customer_say_section .outline-base-btn:hover {
  background: var(--primary);
  color: var(--white);
}

.rcs_customer_say_section {
  background-color: #F5F8F9;
}

.rcs_customer_say_section .rcs_customer_slide_text_content p {
  font-size: 12px;
  line-height: 1.8;
  color: var(--black);
  letter-spacing: 0.4px;
  margin-bottom: 5px;
}

.rcs_customer_say_section .rcs_customer_slide_text_content a {
  color: var(--black);
  font-family: var(--font_2);
  text-decoration: underline;
}

.rcs_customer_say_section .rcs_customer_review_slide_title h3 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.rcs_customer_say_section .MuiRating-sizeMedium {
  font-size: 1rem;
}

.rcs_customer_say_section .name-charAt {
  font-size: 20px;
  font-family: var(--font_1);
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: #0097A7;
  text-align: center;
  line-height: 35px;
  color: var(--white);
  position: relative;
  z-index: 1;
  text-transform: uppercase;
}

.rcs_customer_say_section .name-charAt::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  background-color: #0097A7;
  opacity: 0.1;
  border-radius: 100%;
  z-index: -1;
}

.rcs_customer_say_section {
  padding: 50px 0;
}

.rcs_customer_say_section .slick-slide {
  padding: 10px;
}

.rcs_customer_say_section .rcs_customer_review_slide_item {
  padding: 15px 20px;
  border: 1px solid #DDE0E1;
} */

.insta-iframe {
  height: 410px;
}

.rcs_signup_to_input_field input {
  height: 55px;
  border-radius: 0;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid rgb(20, 52, 74, 0.1);
  background: #FFF;
}

.rcs_signup_to_input_field input::placeholder {
  color: var(--primary);
  opacity: 1;
}

.rcs_signup_to_input_field input:active,
.rcs_signup_to_input_field input:focus {
  border: 1px solid rgb(20, 52, 74, 1);
}


.rcs_signup_to_input_field button {
  margin: 0;
  border: none;
  padding: 0px 20px;
}

.rcs_signup_to_section {
  background: #f6f6f6;
  padding: 80px 0;
}

.rcs_signup_to_section .gs_news_form {
  max-width: 370px;
  margin: auto;
}


@media (min-width:1280px) and (max-width:1439px) {
  .showcase-container {
    padding: 0px;
    max-width: 96%;
  }

  .rcs_hero_slider {
    min-height: 600px;
  }

  .rcs_hero_img h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .rcs_hero_img h1 span {
    font-size: 20px;
    line-height: 30px;
  }

  .rcs_hero_img p {
    font-size: 14px;
    line-height: 20px;
  }

  /* .diamonds-collection {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .diamonds-item-brief img {
    max-width: 250px;
    height: 250px;
  }

  .diamonds-item-brief {
    width: 100%;
  } */

  .outline-base-btn {
    font-size: 13px;
    width: 40%;
    margin-top: 4px;
    padding: 8px 4px;
    margin-right: 0;
    margin-left: 10px;
  }

  /* section.shop-ring-by-style.section-space .slick-slide img {
    width: 97%;
  } */

  /* .gemstone-glamour .item img {
    width: 85%;
  }

  .consult-specialist-section img {
    width: 100%;
  }

  .design-diamond-ring-section .heading-wrapper h2 {
    font-size: 45px;
  }

  section.design-diamond-ring-section .content.pl-3.pr-5 {
    padding-right: 0px !important;
  } */

  .section-space {
    padding: 60px 0;
  }

  /* .design-diamond-ring-section {
    padding-bottom: 70px !important;
  } */

  .design-diamond-ring-section .video-item video {
    /* position: relative; */
    /* top: 120px; */
  }

  /* .design-diamond-ring-section .video-item .content {
    padding-right: 100px;
  }

  .design-diamond-ring-section .content p {
    font-size: 13px;
    width: 100%;
  }

  .design-diamond-ring-section .content .outline-base-btn {
    font-size: 12px;
  } */

  .heading-wrapper h2 {
    font-size: 35px;
  }

  .heading-wrapper p {
    font-size: 13px;
  }

  .custom-design .heading-wrapper {
    width: 79%;
  }

  /* .consult-specialist-section .inner-wrap h2 {
    font-size: 40px;
  }

  .consult-specialist-section .showcase-container>div {
    width: 100%;
    overflow: hidden;
  }

  .rcs_customer_say_section .rcs_customer_slide_text_content p {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    height: 130px;
  }

  .promises-section .item p {
    font-size: 16px;
  }

  .promises-section .heading-wrapper h2 {
    font-size: 28px;
  }

  .promises-section .row div[class*="col-"]:before {
    width: 1px;
    height: calc(100% + 20px);
  } */

  .insta-iframe {
    height: 300px;
  }

  .rcs_footer_content_one .logo img {
    /* width: 90%; */
  }
}

@media (max-width:768px) {
  img {
    width: 100%;
  }
}

@media (max-width:991px) {
  /* .wistia_responsive_padding {
    margin-bottom: 30px;
  } */

  .RingBuilderHome .content {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  /* .design-diamond-ring-section .content .outline-base-btn {
    font-size: 13px;
  }

  .diamonds-collection .diamond-item img {
    width: 100%;
  } */

  .section-space {
    padding: 50px 0;
  }

  .showcase-container {
    padding: 0 10px;
  }

  .rcs_hero_img h1 {
    font-size: 38px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
  }

  .rcs_hero_img p {
    width: 80%;
    margin: auto;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    padding-top: 0;
  }

  .rcs_hero_img button {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    width: 70%;
    float: none;
    margin-right: 0;
    margin-top: 15px;
    padding: 5px;
  }



  /* section.explore-collection.section-space .row {
    margin: 0;
  } */

  .rcs_hero_slider {
    min-height: 100%;
    background-position: center;
    /* padding-bottom: 60px; */
    align-items: center;
  }

  .rcs_hero_slider .rcs_hero_img {
    background: #ffffff75;
    padding: 100px 0;
  }

  .heading-wrapper h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .heading-wrapper p {
    /* font-size: 12px; */
  }

  .rcs_hero_img h1 span {
    font-size: 24px;
  }

  .heading-wrapper span {
    font-size: 20px;
  }

  /* section.shop-diamond-by-shape.section-space .inner-shop-diamond-shape {
    padding: 0px 70px;
  } */

  /* .diamonds-collection::after,
  .shop-diamond-by-shape::after {
    display: none;
  }

  .diamonds-collection .diamond-item .name {
    font-family: var(--font_2);
    font-size: 22px;
    font-weight: 550;
    color: var(--primary);
    margin: 0;
  }

  .diamonds-collection .diamond-item .desc {
    font-family: var(--font_1);
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    margin: 0;
  }

  .diamonds-collection .diamond-item a {
    font-family: var(--font_1);
    font-size: 12px;
    font-weight: 500;
    color: var(--primary);
    border: 1px solid var(--primary);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .design-diamond-ring-section .video-item {
    margin-bottom: 20px;
  }

  .slick-dots li button:before {
    width: 10px !important;
    content: ' ' !important;
    background: #000 !important;
    height: 1px !important;
  }

  .slick-dots li {
    margin: 0 !important;
  }

  .shop-diamond-by-shape .heading-wrapper {
    padding-bottom: 0;
  }

  .explore-collection .item a {
    font-size: 15px;
    font-weight: 400;
    padding: 0;
    margin-bottom: 15px;
  } */

  .heading-wrapper {
    width: 74%;
    margin: auto;
    text-align: center !important;
  }

  /* design-diamond-ring-section */

  /* .design-diamond-ring-section .heading-wrapper {
    text-align: center;
    width: 100%;
  }

  .design-diamond-ring-section .heading-wrapper p {
    width: 100%;
  }

  .design-diamond-ring-section .heading-wrapper span {
    font-size: 20px;
    line-height: inherit;
  }

  .design-diamond-ring-section .heading-wrapper h2 {
    font-size: 30px;
    font-weight: 550;
    line-height: 28px;
  }

  .mobile-slider-ringsetting-home .item {
    text-align: center;

  }

  .mobile-slider-ringsetting-home .item img {
    width: 50%;
    margin: 20px auto 30px;
  }

  .mobile-slider-ringsetting-home .item p {
    margin: auto;
  }

  .mobile-slider-ringsetting-home .item .content {
    padding: 0 !important;
  }

  .mobile-slider-ringsetting-home .slick-dots {
    position: absolute;
    top: 35px;
    bottom: auto;
    display: flex !important;
  }

  .mobile-slider-ringsetting-home .slick-dots li {
    width: 33%;
    height: 100%;
    border-right: 2px solid #ffffff51;
  }

  .mobile-slider-ringsetting-home .slick-dots li:last-child {
    border-right: 0;
  }

  .mobile-slider-ringsetting-home .slick-dots li a {
    font-family: var(--font_2);
    font-size: 20px;
    font-weight: 600;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin: auto;
    background: #ffffff41;
    color: #ffffff97;
  }

  .mobile-slider-ringsetting-home .slick-dots li.slick-active a {
    background: #fff;
    color: var(--primary);
  }

  .mobile-slider-ringsetting-home .slick-list {
    padding-top: 80px;
  }

  .design-diamond-ring-section {
    padding-bottom: 50px !important;
  }

  section.RingBuilderHome.design-diamond-ring-section.section-space .align-items-center.row {
    flex-direction: column-reverse;
  }

  .design-diamond-ring-section .video-item .content {
    position: static;
  } */

  /* .design-diamond-ring-section .video-item video {
    width: 100%;
    position: relative; 
    top: -85px;
    /*  margin-bottom: -90px; 
  }

  section.shop-ring-by-style.section-space .slick-slide img {
    padding: 5px;
    width: 100%;
  }

  section.shop-ring-by-style.section-space .slick-dots {
    bottom: -45px;
  }

  .gemstone-glamour .heading-wrapper p {
    width: 100%;
  }

  .gemstone-glamour .heading-wrapper {
    width: 100%;
  }

  .heading-wrapper span {
    font-size: 20px;
  }

  .gemstone-glamour {
    background-position: left;
  }

  .gemstone-glamour .gemstone-wrapper {
    flex-wrap: wrap;
  } */

  .custom-design .heading-wrapper {
    width: 100%;
    padding: 20px 0;
  }

  /* .consult-specialist-section .row {
    flex-direction: column-reverse;
  }

  .consult-specialist-section {
    padding: 20px;
  }

  .consult-specialist-section .inner-wrap h2 {
    font-size: 30px;
    font-weight: 550;
    padding: 10px 0;
  }

  .consult-specialist-section .inner-wrap span {
    font-size: 20px;
    font-weight: 400;
  }

  .consult-specialist-section .inner-wrap p {
    font-size: 12px;
    font-weight: 400;
  }

  .consult-specialist-section .inner-wrap {
    width: 100%;
    padding: 50px 0;
  } */

  /* .rcs_customer_say_section .rcs_customer_slide_text_content a {
    font-size: 12px;
  } */

  .insta-iframe {
    height: 235px;
  }

  .rcs_signup_to_input_field input,
  .rcs_signup_to_input_field button {
    min-width: 100% !important;
    /* margin-top: 10px; */
  }

  form.gs_news_form.w-100 .input-group {
    display: block;
  }

  /* .rcs_signup_to_section {
    background: url('../Assets/img/mb-bg-news.png');
  } */

  .rcs_logo_head.rcs_sticky-top.sticky-top .rcs_search_bar.mobileSearchBox {
    display: none;
  }

  .rcs_signup_to_input_field {
    margin-top: 15px;
  }
}

@media (min-width:1440px) and (max-width:1900px) {
  .showcase-container {
    max-width: 96%;
  }

  .rcs_hero_slider {
    min-height: 635px;
  }

  /* section.explore-collection.section-space .col-md-3.col-6 {
    padding: 0 8px;
  } */

  /* .rcs_customer_say_section .rcs_customer_slide_text_content p {
    height: 130px;
  }

  .rcs_customer_say_section .rcs_customer_review_slide_title h3 {
    font-size: 13px;
  } */

  /* .consult-specialist-section img {
    width: 100%;
  }

  section.shop-ring-by-style .slick-slide img {
    width: 100%;
    padding: 10px;
  }

  .promises-section .item p {
    font-size: 17px;
  } */

  .heading-wrapper h2 {
    font-size: 30px;
  }

  /* .design-diamond-ring-section .video-item video {
    margin-top: 55px;
  }

  section.design-diamond-ring-section.section-space.pb-0 {
    padding-bottom: 100px !important;
  }

  .diamonds-collection {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }

  .diamonds-item-brief {
    width: 100%;
  } */

  .rcs_shape_wizard_content ul li.rcs_shape_wizard_img {
    right: 42px !important;
  }

  .rcs_bg-slide li {
    font-size: 13px !important;
    padding-bottom: 4px !important;
  }

  .appointment-report-btn .rcs_border_btn {
    font-size: 12px !important;
  }

  .ShowcaseRingseting_ProdFilter li svg {
    width: 70px !important;
    height: 70px !important;
  }

  .ShowcaseRingseting_ProdFilter {
    flex-wrap: wrap !important;
  }

  /* .consult-specialist-section .inner-wrap h2 {
    font-size: 36px;
  } */

  .insta-iframe {
    height: 335px;
  }
}


@media (min-width:769px) and (max-width:991px) {}

@media (min-width:991px) and (max-width:1279px) {
  /* .diamonds-collection .diamond-item img {
    margin: auto;
  } */

  .rcs_hero_img h1 {
    font-size: 40px;
  }

  /* .consult-specialist-section img {
    width: 100%;
  } */

  .showcase-container {
    padding: 0 10px;
    overflow: hidden;
  }

  /* .diamonds-collection {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .diamonds-item-brief img {
    max-width: 200px;
    height: 200px;
    margin-right: 0px;
  }

  .diamonds-item-brief {
    width: 100%;
  } */

  .outline-base-btn {
    font-size: 12px;
  }

  /* section.shop-ring-by-style.section-space .slick-slide img {
    width: 100%;
    padding: 4px;
  }

  .gemstone-wrapper {
    flex-wrap: wrap;
  }

  .gemstone-glamour .item {
    text-align: center;
    padding: 0 20px;
  } */

  .heading-wrapper h2 {
    font-size: 30px;
  }

  /* .design-diamond-ring-section .heading-wrapper h2 {
    font-size: 40px;
  } */

  .section-space {
    overflow-x: hidden;
  }

  /* section.rcs_customer_say_section .slick-next {
    right: 0;
  }

  section.rcs_customer_say_section .slick-prev {
    left: 0;
  }

  .consult-specialist-section .inner-wrap {
    padding: 30px 0;
    width: 100%;
  } */

  .section-space {
    padding: 40px 0;
  }

  /* .gemstone-glamour .heading-wrapper p {
    width: 100%;
    margin: 0 auto;
  } */
}

/* .rcs_customer_review_slide_img img {
  width: auto;
} */

@media (min-width:767px) and (max-width:1023px) {
  section {
    overflow-x: hidden;
  }

  /* .diamonds-collection .diamond-item img {
    width: auto !important;
    margin: auto;
  }

  .diamonds-collection .diamond-item .desc {
    width: 75%;
    margin: auto !important;
  } */

  /* .RingBuilderHome.design-diamond-ring-section .row .col-md-5.col-12,
  .RingBuilderHome.design-diamond-ring-section .row .col-md-7.col-12,
  section.gemstone-glamour .col-md-6,
  .consult-specialist-section .col-md-6,
  .custom-design .col-md-6,
  #rcs_footer_bottom .col-lg-4.col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .custom-design .col-md-6 img {
    width: auto;
  } */

  .insta-iframe {
    height: 175px;
  }

  /* .consult-specialist-section img {
    width: 100%;
  }

  .gemstone-glamour {
    background-size: cover;
    background-position: right;
  } */
}

@media (min-width:540px) and (max-width:991px) {
  /* .gemstone-glamour .gemstone-wrapper {
    flex-wrap: nowrap;
  }

  .gemstone-glamour .item img {
    width: 70%;
  }

  .gemstone-glamour .item p {
    font-size: 10px;
  }

  .gemstone-glamour {
    background-image: linear-gradient(45deg, #2a3340, #1e252e);
  } */
}

@media (min-width:769px) and (max-width:990px) {
  /* .diamonds-collection .diamond-item .desc {
    line-height: 20px;
    min-height: 68px;
  } */
}

.London_theme_conteiner {
  max-width: 1200px;
  margin: auto;
}

/* Home Aboute */

.EngagementWedding {
  padding-top: 80px;
}

.EngagementWedding .row {
  display: flex;
  align-items: center;
}

.as_desc {
  padding-left: 50px;
}

.as_desc .pretitle {
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: .05em;
  color: #a99575;
  margin-bottom: 13px;
  font-weight: 700;
}

.as_desc h6 {
  font-size: 40px;
  font-weight: 400;
  line-height: 55px;
  padding: 0 0 30px 0;
  letter-spacing: 0;
  font-family: 'Playfair Display', sans-serif;
}

.as_desc p {
  font-size: 18px;
  font-weight: 400;
  color: #7b7b7b;
  line-height: 32px;
}

.shop_btn {
  margin-top: 50px;
  background: #2c2c2c;
  text-transform: uppercase;
  font-weight: 500;
  height: 55px;
  letter-spacing: .12em;
  padding: 0px 40px;
  border-radius: 0;
  line-height: 55px;
  color: #fff;
  font-weight: 800;
}

.shop_btn:hover,
.shop_btn:focus {
  background: #a99575;
  color: #fff;
  outline: none;
}

.title-box2 h2 {
  margin-bottom: 15px;
  color: #222;
}

.WhyBuy {
  padding: 100px 0;
  background: #f6f6f6;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: 80px;
}

.WhyBuy .title-box2 h2 {
  font-size: 40px;
  line-height: 55px;
  font-weight: 400;
  letter-spacing: 0;
  font-family: 'Playfair Display', sans-serif;
  padding-bottom: 30px;
  margin-bottom: 15px;
}

.WhyBuy .am_box {
  text-align: center;
}

.WhyBuy .am_box img {
  margin-bottom: 10px;
}

.WhyBuy .am_box h6 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: .04em;
  font-weight: 600;
  margin-top: 10px;
}

.WhyBuy .am_box p {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  color: #7b7b7b;
  margin-top: 20px;
}

.WhyBuy .abt-heading {
  margin-top: 50px;
  color: #a99575;
  letter-spacing: 1px;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  border-bottom: 1px solid #a99575;
}

.Home-banner-video {}

.Home-banner-video .overlay {
  background: rgba(0, 0, 0, 0.60);
  position: relative;
  top: 0;
  width: 100%;
  height: 99%;
  z-index: 9;
  padding: 250px 0;
  visibility: visible;
  opacity: 1;
}

.Home-banner-video .banner-thumb h2 {
  font-family: 'Playfair Display', sans-serif;
  color: #fff;
  font-size: 70px;
  line-height: 70px;
  font-weight: 400;
  -moz-transition: color .2s linear;
  -o-transition: color .2s linear;
  -webkit-transition: color .2s linear;
  transition: color .2s linear;
  margin-bottom: 20px;
}

.Home-banner-video .banner-thumb p {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  /* width: 540px; */
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 20px;
}

.Home-banner-video .video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Home-banner-video .btn-outline a {
  display: table;
  border: 2px solid #fff;
  border-radius: 0;
  padding: 10px 20px;
  min-width: 170px;
  color: #fff;
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.Home-banner-video .btn-outline a:hover {
  background-color: #fff;
  color: var(--primary);
}

.Home-banner-video .video-container video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Home-banner-video .item-slider {
  position: relative;
}



/*Showroom Section*/
.LearneDiamondShowroom {
  padding: 100px 0;
}

.LearneDiamondShowroom .zoom-image {
  overflow: hidden;
  position: relative;
}

.LearneDiamondShowroom .zoom-image img {
  transition: all .5s ease-out 0s;
  -webkit-transition: all .5s ease-out 0s;
  -moz-transition: all .5s ease-out 0s;
  -o-transition: all .5s ease-out 0s;
  width: 100%;
  min-height: 750px;
  object-fit: cover;
}


.LearneDiamondShowroom .zoom-image:hover img {
  transform: scale(1.1);
  overflow: hidden;

}

.LearneDiamondShowroom .contentsec {
  padding: 25px 0;
  text-align: center;
}

.LearneDiamondShowroom .contentsec h1 {
  font-family: 'Playfair Display', sans-serif;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 40px;
  line-height: 55px;
}

.LearneDiamondShowroom .contentsec p {
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 27px;
}

.LearneDiamondShowroom .position-btn {
  content: '';
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.LearneDiamondShowroom .position-btn a {
  padding: 10px 42px;
  min-width: 170px;
  max-width: 200px;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  border: 2px solid #fff;
  display: none;
  font-weight: 800;
  margin: 0 auto;
}

.LearneDiamondShowroom .position-btn a:hover {
  background: #fff;
  color: #000;
}

.LearneDiamondShowroom .zoom-image:hover .position-btn a {
  display: block !important;
}


/* GoogleReview */

.GoogleReview {
  background-color: #7f6750;
  padding: 60px 0 40px;
  margin-bottom: 70px;
}

.GoogleReview .client-tabs {
  max-width: 80%;
  margin: auto;
}

.GoogleReview .reviews_icon li img {
  width: 15px !important;
  margin-bottom: 10px;
}

.GoogleReview h2 {
  font-size: 40px;
  line-height: 55px;
  font-weight: 400;
  letter-spacing: 0;
  color: #fff;
  font-family: 'Playfair Display', sans-serif;
}

.GoogleReview .desc {
  margin-bottom: 15px;
  color: #fff;
  font-family: 'Playfair Display', sans-serif;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 400;
}

.GoogleReview .reviews_icon li {
  display: inline-block;
}

.GoogleReview h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #fff;
}

.GoogleReview .review_read_more {
  display: block;
  color: #f5f5f5;
  font-weight: 700;
}

.GoogleReview .name {
  text-transform: capitalize;
  color: #fff;
}

.GoogleReview .client-tabs .nav button {
  background-color: transparent;
  border: 0;
  padding: 5px;
  margin: 20px 30px;
  border-radius: 4px;
}

.GoogleReview .client-tabs button img {
  border-radius: 4px;
}

.GoogleReview .client-tabs button.active {
  background-color: #d2ae76;
}

.GoogleReview .client-tabs .slick-arrow.slick-prev {
  left: -65px;
  width: 35px;
  height: 35px;
}

.GoogleReview .client-tabs .slick-arrow.slick-next {
  right: -65px;
  width: 35px;
  height: 35px;
}

.CreateRing {
  padding: 50px 0;
  background: #f6f6f6;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.custom-design-btn {
  border: 2px solid var(--primary);
  color: var(--primary);
  text-transform: uppercase;
  display: inline-block;
  max-width: 200px;
  width: 100%;
  padding: 8px 0;
  font-weight: 800;
}

.custom-design-btn:hover {
  background: var(--primary);
  color: #fff;
}

.CreateRing .shop_nw_btn {
  border: 2px solid var(--primary);
  color: var(--primary);
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px 20px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.CreateRing .decs {
  max-width: 80%;
  margin-left: 0;
}

.text-dark {
  color: #000;
}

.rcs_signup_to_section .subscribe-box {
  max-width: 440px;
  margin: 0 auto;
}

.rcs_signup_to_section .border-left {
  border-left: 1px solid #d9d9d9;
}

.rcs_signup_to_section .subscribe-box h2 {
  margin-bottom: 25px;
  font-size: 34px;
  line-height: 44px;
  font-weight: 400;
  letter-spacing: .03em;
}

.rcs_signup_to_section .subscribe-box p {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #7b7b7b;
}

.rcs_signup_to_section .subscribe-box p a {
  color: var(--primary);
}

.subscribe-box .form-newsletter2 {
  margin-top: 35px;
}

.GoogleReview .client-tabs .tab-content .tab-pane {
  transition: all .5s ease;
}

.custom-design {
  padding-bottom: 100px;
}

@media (min-width:1440px) and (max-width:1900px) {
  .rcs_logo_head.rcs_sticky-top .rcs_menu.menu_list_main>.container-fluid {
    max-width: 1450px;
  }

  .home_banner_content {
    left: 56px;
  }
  .GoogleReview {
    margin-bottom: 60px;
}
}

@media (min-width:1280px) and (max-width:1439px) {
  .rcs_logo_head.rcs_sticky-top .rcs_menu.menu_list_main>.container-fluid {
    max-width: 1270px;
  }

  .home_banner_content {
    left: 0px;
    width: 100%;
  }

  .rcs_logo_head.rcs_sticky-top .sticky_logo img {
    max-width: 175px;
  }
  .GoogleReview {
    margin-bottom: 40px;
}
}

@media (max-width:991px) {
  .CreateRing .shop_nw_btn {
    min-width: 245px;
    margin-right: 0;
  }

  .d-xs-block {
    display: block !important;
  }

  .home_banner_content {
    width: 100%;
    position: relative;
    left: 0;
  }

  .as_desc {
    padding-left: 0;
    margin-top: 20px;
  }

  .as_desc .pretitle {
    margin-bottom: 0;
  }

  .as_desc h6 {
    padding-bottom: 0;
  }

  .shop_btn {
    margin-top: 0px;
  }

  .EngagementWedding {
    padding-top: 40px;
  }

  .WhyBuy .am_box img {
    margin-bottom: 10px;
    width: auto;
  }

  .WhyBuy {
    padding: 50px 0;
  }

  .WhyBuy .title-box2 h2,
  .as_desc h6 {
    font-size: 32px;
    line-height: 38px;
    padding: 15px 0;
  }

  .WhyBuy .abt-heading {
    margin-top: 20px;
  }

  .Home-banner-video .overlay {
    padding: 20px 0;
  }

  .Home-banner-video .banner-thumb h2 {
    font-size: 30px;
  }

  .Home-banner-video .banner-thumb p {
    color: #fff;
    font-size: 15px;
    line-height: 22px;
    width: 95%;
    margin: 0 auto;
  }

  .Home-banner-video .btn-outline a {
    padding: 5px 0;
    min-width: 150px;
    font-size: 14px;
    margin-top: 8px;
  }

  .GoogleReview {
    margin-bottom: 25px;
  }

  .Home-banner-video .video-container video {
    height: 400px;
    width: 213%;
  }

  .custom-design {
    padding-bottom: 32px;
  }

  .LearneDiamondShowroom .zoom-image img {
    min-height: 450px !important;
    max-height: 400px !important;
    margin: auto;
    object-fit: cover;
  }

  .LearneDiamondShowroom .contentsec img {
    width: auto;
  }

  .LearneDiamondShowroom {
    padding: 40px;
  }

  .heading-wrapper {
    padding-bottom: 0;
  }

  .CreateRing .decs {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .GoogleReview .client-tabs .nav button {
    padding: 5px;
    margin: 0px 10px;
  }

  .GoogleReview .client-tabs .nav button img {
    width: 100px;
    height: auto;
  }

  .GoogleReview h2 {
    font-size: 33px;
    line-height: 45px;
  }

  .GoogleReview .desc {
    font-size: 16px;
    line-height: 26px;
  }

  .GoogleReview .client-tabs .slick-arrow.slick-prev {
    left: -50px;
  }

  .GoogleReview .client-tabs .slick-arrow.slick-next {
    right: -50px;
  }

  .rcs_signup_to_section .border-left {
    margin-top: 40px;
  }

  .subscribe-box .shop_btn {
    margin-top: 20px !important;
  }

  .rcs_signup_to_section .border-left {
    border-top: 1px solid #d9d9d9;
    border-left: 0 !important;
    padding-top: 30px;
  }

  .CreateRing img {
    width: 80%;
  }

  .LearneDiamondShowroom .contentsec h1 {
    font-size: 33px;
  }
}

@media (min-width:991px) and (max-width:1279px) {
  .home_banner_content {
    width: 100%;
    position: relative;
    left: 0;
  }

  .rcs_hero_img h1 span {
    font-size: 24px;
    line-height: 24px;
  }

  .outline-base-btn {
    margin-top: 0;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .rcs_signup_to_section .border-left {
    border-left: 1px solid #dee2e6 !important;
    border-top: 0;
    margin-top: 0px;
    padding-top: 0px;
  }

  .CreateRing .heading-wrapper.text-center.d-none.d-xs-block {
    display: none !important;
  }
}